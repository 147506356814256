import "./Card.css";
import React from "react";

export default function Card({title, question, subtitle}) {
  return (
      <div className="card col-12">
        <div className="title">
          <h2>{title}</h2>
        </div>
        <span className="question">
          {question}
        </span>
        <div className="subtitle">
          <h3>{subtitle}</h3>
        </div>
      </div>
  );
}