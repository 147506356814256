export const cardRules = {
  GREY: {
    rule: "Svara själv",
    time: 30
  },
  RED: {
    rule: "Utse någon som får svara",
    time: 30
  },
  BLACK: {
    rule: "Diskutera dilemmat i grupp",
    time: 120
  }
}