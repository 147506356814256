export const questions = [
  "dens teamlead smiter så fort det hettar till. Kommer tillbaka när problemet är löst och tar åt sig äran.",
  "En teammedlem i uppdraget kommer för sent till möten, vet inte vart hen ska, vilket mötesrum som är bokat och kommer alltid med lama ursäkter. Du tycker att det är störande.",
  "Under teammöten är det alltid en och samma person som har usel telefon-/dator-disciplin och är “väldigt upptagen med viktiga saker” som inte ingår i mötet.",
  "Du erbjuds ett uppdrag men det ligger långt från hemmet och du får en timmas restid per väg. I övrigt är uppdraget intressant och tekniken är kul.",
  "Du erbjuds ett uppdrag på Vapentobak & Spelalkohol AB som alltid är i hetluften på etiska grunder. Projektet är spännande och tekniken precis i samklang med din kompetensplan. Sådana här uppdrag växer inte på träd...",
  "Du har jobbat i projektet i ett halvår och ni får en ny teamlead. Kemin mellan er funkar bara inte. Hen ger otydliga instruktioner och förstår inte vad du frågar efter. Resten av teamet är kanonbra och du känner verkligen för produkten.",
  "En av Cygnis säljare kommer med ett förslag på ett uppdrag och en intervju där de söker en javautvecklare. Det enda du får veta är företagsnamnet.",
  "Du har uttryckligen sagt att du vill kompetensutveckla dig inom ett specifikt område. Din konsultchef har godkänt din plan och ni har kommit överens om att du ska certifiera dig. Nu kommer säljaren med ett nytt uppdrag till dig ... inga molntjänster i sikte på de närmaste 2 åren...",
  "Teknik är det bästa du vet. Mambojambo om mjuka värden och känslor är bara bortkastad tid. Du vill koda! Din konsultchef tjatar om att du ska lägga kompetensutvecklingstid på gruppdynamik.",
  "Två av dina teamkollegor är urusla på kommunikation. Det är egen sjukdom, sjuka barn, hemleveranser om vart annat. Ditt team har svårt att komma framåt i arbetet.",
  "I projektet du jobbar i är det tajt med tid och korta deadlines pga kundleveranser. Man “har alltid jobbat så” och det där med test har fått stå tillbaka. Det tar ju sådan tid att skriva tester först... kundens kund vill ju ha sin leverans snabbt. Du vet att kodkvaliteten och minskning av buggar enkelt skulle minska om ni byter strategi till testdriven utveckling.",
  "Du får kritik av produktägaren/utvecklingschefen som du uppfattar som orättvis. Kritiken är att du ägnar för mycket tid åt att finslipa och överarbeta. Du vet ju att det kommer surt efter om du inte får fixa till det där lilla också...",
  "Du erbjuds ett uppdrag på Vapentobak & Spelalkohol AB som alltid är i hetluften på etiska grunder. Projektet är spännande och tekniken precis i samklang med din kompetensplan. Sådana här uppdrag växer inte på träd...",
  "Du har jobbat i projektet i ett halvår och ni får en ny teamlead. Kemin mellan er funkar bara inte. Hen ger otydliga instruktioner och förstår inte vad du frågar efter. Resten av teamet är kanonbra och du känner verkligen för produkten.",
  "Ni har kommit långt i projektet och det har varit ett riktigt spännande jobb. Du känner att det ska bli kul att få ut den nya plattformen i produktion. Yey! Då får ni plötsligt halt i projektet eftersom kunden drar tillbaka sin beställning. De har fått varsel och pengar saknas när de inte landade en stor affär. Tråkigt att behöva gå tillbaka till att lappa och laga det gamla systemet som alla “hatar”. Alla tappar motivationen.",
  "Du är på en intervju till ett nytt uppdrag. Efter intervjun möter du en från teamet som Cygni har tackat nej till pga att hen gjorde en dålig teknikintervju. Det förväntas att ni ska jobba tillsammans om du tackar ja till uppdraget.",
  "Du är ny på ett uppdrag. På intervjun beskrivs uppdraget som superagilt. Du fick inte möjligheten att grotta ner dig så mycket i detta på intervjun. Efter tre veckor på uppdraget upptäcker du att det enda agila är att det finns ett stand-up varje morgon.",
  "Du har varit på intervju hos kunden utan allt för mycket bakgrundsinfo inför intervjun. De har frågat efter “bra utvecklare” men inte preciserat vilken produkt och vilket team du ska jobba med. Tekniken känns helt rätt utom att de använder EPiServer för sin webb. Du gillar inte EPi. Kunden tycker att du passar utmärkt i bolagetes stora HEMSIDA.se lösning och du får en teamleadroll som du länge velat testa.",
  "I ditt team på uppdraget finns det enbart konsulter. En konsult från ett annat företag berättar att han idlar rätt mycket för att han inte vill skämma bort kunden eftersom han är så snabb. Han tycker det är rätt gött att chilla.",
  "Er arkitekt på uppdraget missar ständigt deadlines, är alltid sen till möten och verkar egentligen inte ha greppat essensen i lösningen. Arkitekten är anställd hos kunden och har väldigt bra renommé.",
  "Du tycker det är extremt svårt att få ihop livspusslet just nu. Precis fått barn, flyttat till nytt hus som skall renoveras. Motivationen för jobbet är just nu inte på topp. Du har tighta avstämningar med din KC, men du tycker hen är på dig för mycket och det bättrar inte på din motivation.",
  "Din kollega i teamet jobbar remote 2 dagar i veckan och ringer alltid in för sent till Stand-up, typ 5 minuter. När hen dyker upp får teamet alltid dra en snabbgenomgång av vad som redan sagts. Du tycker detta är extremt störigt, men teamet har redan hintat att dom tycker du är en tids-facist, så du vill egentligen ligga lite lågt.",
  "Ni är ett team på 8 personer där 2 personer alltid tar extremt stor del av stand-up tiden och har varken uppdaterat sitt board eller förberett sig, vilket teamet har som en ground rule. Scrum Mastern är ganska svag i sin roll och låter detta hända gång efter gång. Scrum Mastern är anställd hos kunden och dessutom dottern till ägaren.",
  "Du har jobbat med en riktig surdeg som ingen vill ta tag i och nu har du löst problemet. Din teammedlem tar hela tiden åt sig av äran och ger inte dig cred för arbetet. Ditt hästjobb är helt osynligt.",
  "Du är så sjukt trött på ditt nuvarande uppdrag. Du har sagt detta till din KC en längre tid nu och du har verkligen lyft upp allt som är dåligt på ditt uppdrag så att hen ska hjälpa dig därifrån så fort som möjligt. Din KC har stolt presenterat ett uppdrag till dig som mödosamt letats upp. Det är mycket som är rätt i uppdraget men saknar det som ligger högst upp på din önskelista. Du vill isåfall hellre backa tillbaka till ditt gamla uppdrag.",
  "Kollegan i teamet fullkomligt stinker svett, det är rent outhärdligt. Hen känner det inte själv och har dessutom inte samma avstånd i den personliga sfären som du har. Hen gillar verkligen att vara nära. Du står bara inte ut.",
  "Du får en fråga av Cygnis säljare kring vad du har gjort i ett tidigare uppdrag där de vill att du beskriver vad systemet gjorde, dess arkitektur och teknikstack. Säljaren behöver svar asap. Just nu är det extremt körigt i projektet och du känner att du behöver prioritera andra saker.",
  "Du har just nu ett uppdrag på 50% hos en rolig kund och ni har tillsammans med kunden bestämt att du anpassar dina arbetstider hos kunden till behoven tills du har fått ytterligare ett deltidsuppdrag. Tidigt i veckan är du redan klar med veckans uppgifter och behövs inte tills nästa vecka.",
  "Teamet hos kunden har just gjort en väldigt lyckad release av ett nytt system och du känner att det skulle vara kul att fira med att t.ex. gå ut och ta en öl med teamet eller en köra en teamlunch. Kunden har sagt att det inte finns utrymme i deras budget för firande.",
  "Du är ny i ditt uppdrag och kommer med förslaget att parprogrammera, något man inte gjort tidigare. Det finns tydliga bevis på vinsterna med metoden men en av utvecklarna, som har varit länge i temat och är tongivande, tycker att det är en dålig idé och vill inte att teamet testar metoden.",
  "Du kommer tillbaka efter din semester redo för att fortsätta på ditt projekt, men när du kommer tillbaka har dina arbetsuppgifter redan färdigställts och samtliga chefer och teamleads är på semester. Du har så här långt bara blivit tilldelad specifika uppgifter och har nu ingenting på din lista.",
  "Du har jobbat på ditt uppdrag i ungefär en månad nu och trivs bra, men du upplever att dina kollegor anställda av företaget tar väldigt långa fikapauser. De bjuder alltid in dig att följa med, men till skillnad från dem så debiterar ju du...",
  "Du sitter på ett uppdrag sen ett tag tillbaka och du får en ny teammedlem från Cygni. Ganska snart visar det sig att hen inte levererar alls och inte har den kompetensen som uppdraget kräver. Även kunden märker detta och kommer därför till dig och frågar lite försiktigt: “Hur går det för din kollega egentligen?”",
  "Du sitter på ett uppdrag sen ett tag tillbaka och du får en ny teammedlem från ett konkurrerande bolag. Ganska snart visar det sig att hen inte levererar alls och inte har den kompetensen som uppdraget kräver. Även kunden märker detta och kommer därför till dig och frågar lite försiktigt: “Hur går det för din kollega egentligen?”",
  "Du får höra att en bekant till dig ska på intervju till Cygni på rekommendation av din kollega som du har stort förtroende för. Din bild av din bekanta skiljer sig dock mycket från din kollegas, och du har väldigt svårt att se din bekanta som en god Cygni-kollega.",
  "Du är på din första intervju för ett första uppdrag som junior utvecklare. Du känner att tekniken och projektet är mycket intressant, men uppdragsgivaren är rörig och att du inte riktigt vet vad du kan förvänta dig.",
  "Du tackar ja till ett uppdrag med premissen att du får gå under en senior utvecklare och därmed lära dig ny teknik. Efter en månad på uppdraget slutar den seniora utvecklaren.",
  "Du jobbar i ett team där de flesta kommer överens, men en teammedlem verkar ofrivilligt stå utanför gruppen och blir inte tillfrågad om att följa med och äta lunch, blir tydligt ignorerad på möten och möts av korta och snäsiga svar av teamet. Du själv har inga andra problem med varken den ensamma teammedlemmen eller övriga i gruppen.",
  "En i teamet kommer alltid insläntrande en timma efter alla andra med nya ursäkter varje dag, trots att hen börjar senare går ni hem samtidigt. Hen loggar också alltid 8 timmar. I övrigt är personen en i gänget och gör alltid sitt jobb.",
  "En av dina teammedlemmar luktar ofta väldigt mycket svett och du märker att andra i teamet drar sig för att prata eller sitta nära. Dessutom börjar kundens personal skämta om personen bakom hens rygg. Personen själv tycks helt omedveten om sin mustiga kroppsodör.",
  "Du jobbar i ett väldigt tidspressat och prioriterat projekt hos en kund. En dag kommer den väldigt stressade utvecklingschefen förbi och säger att från och med nu och fram till nästa kundleverans om två månader får ingen skriva några nya enhetstester, “vi har inte tid med det”. Du upplevde redan innan att testtäckningen var för dålig och att systemet har kraftiga kvalitetsproblem. Att ytterligare dra ner på testerna kommer på sikt innebära en katastrof.",
  "Du sitter i ett uppdrag där ett annat konsultbolag är den dominerande leverantören. Du märker hur de aktivt motarbetar alla andra konsultbolag och intrigerar för att bara gynna sig själva. Du överhör på en fika att de efter en intervju med kandidater till ett nytt uppdrag för kunden, kommer se till att deras egen konsult väljs ut trots att hen verkligen inte var den mest kvalificerade kandidaten.",
  "Cygni har fått en förfrågan från en kund som söker en senior utvecklare/tech lead. Du åker på intervju tillsammans med en säljare och ni känner under intervjun att det här uppdraget snarare är något som kanske skulle passa bättre för en väldigt junior konsult. Men intervjun är tidspressad och ni hinner inte ställa så många frågor. Efter bara 30 minuter sträcker kunden fram handen och säger “Vi är nöjda, det här känns kanoners, du får börja på måndag”.",
  "Kundens projektledare kommer fram till dig när hela teamet sitter samlat och säger “Jag vet att det är Lisas tur att ha beredskap hela den här helgen men hon har tänkt åka på en weekendkryssning så teamet skulle vilja att du tar beredskapen istället”. Problemet är att din lillebrors svensexa är på lördag.",
  "Du har suttit på uppdraget i ett år. Du är den enda i teamet som har kompetens inom ett specifikt område. Din teamleader har precis uttryckt att de är beroende av dig, men du vill egentligen bara gå vidare till ett nytt uppdrag.",
  "Du har suttit på ett uppdrag i en vecka och känner redan att det inte blivit som du tänkt dig. Du trivs inte på uppdraget och vill därför rulla av och byta uppdrag.",
  "Din kollega smiter alltid iväg och tränar när det börjar närmar sig lunchtid. När kollegan kommer tillbaka efter träningen ska det också ätas. Eftersom teamet är beroende av kollegan uppstår det ett problem när hen tar två timmars lunch.",
  "Du har sedan länge planerat och bokat drömresan, men du har precis börjat på ett nytt uppdrag där det visar sig att arbetsplatsen stänger ner under vissa veckor av året. De uppmanar alla att ta semester denna tid, men det var inte under de veckorna som du hade planerat. Detta är något som inte har framkommit under din intervju, och du har heller inte nämnt resan tidigare.",
  "Du har precis förnyat kontraktet med din uppdragsgivare med ett löfte om att du ska få arbeta med en annan del av den produkt de utvecklar. Detta för att du ska få möjlighet att bredda dig kompetensmässigt. Dock går tiden och ingenting händer. Du är fast med samma gamla uppgifter som tidigare. Det var ju inte alls som du förväntat dig.",
  "Du berättar för en i teamet om ett nytt frontend- ramverk som skulle passa perfekt in på uppdraget. Teammedlemmar och uppdragsgivaren verkar skeptisk och allt rinner ut i sanden. På nästa avdelningsmöte berättar chefsarkitekten om ett nytt frontend-ramverk som de, efter tips från din teamkamrat, ska börjar undersöka noggrannare. Du förbluffas över detta, det var ju trots allt din idé.",
  "Du är på intervju för ett uppdrag där det framkommer att de har en väldigt strikt klädkod som inte helt lirar med vad du är bekväm i. Utöver det är uppdraget som klippt och skuret för dig.",
  "Du är en användare av Linux ut i fingerspetsarna och har svårt att föreställa dig att arbeta med någon annan plattform. Nu erbjuds du ett uppdrag där alla måste köra Windows.",
  "Dina kollegor drar mycket skämt om “hur konsulter är”. Det är inget illa ment men det börjar bli väldigt ofta och du som enda konsult på uppdraget känner dig lite utpekad.",
  "Du har suttit på ditt uppdrag ett bra tag och din linjechef är nöjd med ditt arbete. Det finns dock en fast anställd kollega som ofta tar upp ämnet om timdebitering. Dessutom får du intrycket av att kollegan kastar blickar på dig så fort du vänder dig om i stolen för att ta en kort paus eller för att delta i ett samtal med kollegor.",
  "Du ska snart på semester och du har åtagit dig att göra färdigt ditt projekt innan du åker iväg. I början av vecka 30 så märker du att du har tagit semester redan vecka 31 och resan är redan bokad - du har helt blandat ihop veckorna och nu blir det svårt att hinna färdigt.",
  "Du sitter på ett uppdrag där det finns en uppsjö av nya tekniker som alla har sina små egenheter som du inte känner till. Du måste ofta ställa frågor till dina kollegor då du inte lyckas hitta alla detaljer på egen hand, och du känner att dina kollegor måste lägga mycket tid på att svara på dina frågor.",
  "Du sitter på ett trevligt uppdrag hos en kund där Cygni är väletablerade sen ett bra tag tillbaka. En veteran på arbetsplatsen frågar dig ofta lite lågmält om hur det är att jobba som konsult och säger en dag i princip rakt ut att han funderar på att ansöka till Cygni och testa något annat. Du vet att personen i fråga är en väldigt viktig pusselbit på din nuvarande arbetsplats för att verksamheten ska fungera.",
  "Du har precis påbörjat ditt nya uppdrag. Allt känns frid och fröjd med teamet och tekniken, dock upptäcker du ganska snabbt att det kunden sa på intervjun om “work anywhere” inte gäller i detta teamet. För dig är det viktigt med flexibilitet och 8-17 på kontorsstolen dödar din kreativitet och motivation.",
  "Solen skiner och det är högsommar. Det har ryktats om att man inte får gå med shorts på kontoret, något som du tycker är löjligt. Det är ju svinvarmt! Du kör shorts och efter en vecka har du fått många peakar kring det - men ingen formell ledare har sagt något.",
  "Du sitter på bästa uppdraget med din drömstack. Dock kräver din Scrummaster att ni nu skall ta direktkontakt med slutanvändaren för att säkra att ni uppfattat tasken rätt innan ni börjar koda -och sedan ha löpande kontakt för frågor och test. Du tycker detta tar alldeles för mycket av din kodtid att hålla i den dialogen.",
  "När du blev anställd på Cygni var det mycket snack om det lite ovanliga ledarskapet, att man faktisk väljer sin egen chef. Du gillar din KC väldigt mycket och har ingen kritik, men efter fyra år kan du känna att det vore spännande att byta för att få nya perspektiv. Dock har du aldrig hört talas om någon som bytt, så trots vad som sägs tror du din KC kan ta det personligt.",
  "Du har varit på ditt uppdrag i ett år och kunden har bra förtroende för dig. Ni har ett bra team, men du uppfattar inte att alla i teamet är lika engagerade, men ni når era deadlines och det sociala funkar fint. Ibland kan du irritera dig lite på att vissa anställda gärna spelar pingis lite för länge trots att er backlog är väldigt lång. Du tror helt enkelt ni kunde ökat er leveranstakt avsevärt om alla taggade till.",
  "Du och ditt team har varit på lunch. Lite senare på dagen ser du att din kollega har ett stort spenatblad mellan tänderna.",
  "Din chef på uppdraget kommer ut från toaletten och går direkt in till teammöte. Chefen står och håller presentation med öppen gylf.",
  "Du har bjudit hela ditt team på AW på Cygnis HK. Väl på plats på HK märker du att fler än du bjudit in och har köpt mat och dryck för dyker upp.",
  "På Cygnis kundfest ser du att en person, från en stor och viktig kund till Cygni, har lagt ett Legospel i väskan. Detta Legospel är inte en freebie utan tänkt att byggas på plats. Legots värde är närmare 800kr.",
  "Du är ute på krogen med dina kompisar. Vid baren ser du en teammedlem som är väldigt berusad och stökig. Du tycket att det är väldigt pinsamt beeteende.",
  "Din kollega från Cygni sitter på AW med kunden. Efter några öl börjar Cygnikollegan snacka skit om Cygni till kunden.",
  "Det är lördagsmorgon och det var en bra AW igår. Väl hemma inser du att datorn är borta. Du har sökt efter den hela helgen utan framgång och måndagen närmar sig...",
  "Du och ditt team är på AW. Du har fått OK från din KC att bjuda på en öl i baren. I baren säger du “Cygni bjuder” varpå din teammedlem beställer en magnumflaska Champagne.",
  "Du är på en aktivitet med Cygni och det tas fram ett så kallat Dilemmaspel, du tycker det känns meningslöst och tråkigt.",
  "Du har bokat en Cygniaktivitet som utförs under en helg utanför arbetstid. Du har tackat ja till aktiviteten som är bindande då det är en kostnad för Cygni förenad med aktiviteten. Samma vecka inser du att aktiviteten krockar med en annan aktivitet du hellre vill gå på.",
  "Din teammedlem i uppdraget har ganska grov humor och skickar varje fredag sexskämt i teamslacken och kallar dem för “fredagsfräckisen”. Resten av teamet skrattar åt dessa skämt men du tycker inte det är lämpligt.",
  "Du har, sedan du började på Cygni, skrytit om dina kunskaper i skidbacken när du i själva verket aldrig stått på ett par skidor. Nu närmar sig årets konferens och det visar sig vara just en skidresa.",
  "Du mejlar en kollega och bjuder in hen till din födelsedagsfest. Till din förskräckelse upptäcker du att din KC, som du inte vill ska komma, är CC:ad i mejlet av misstag. Din KC mejlar snabbt tillbaka och tackar gladeligen ja till inbjudan.",
  "Du har arbetat i två veckor på uppdraget och den ansvariga på projektet har fortfarande inte lärt sig ditt namn utan tilltalar dig hela tiden felaktigt. Resterande i teamet låter det passera men du börjar tycka att det är tröttsamt efter att ha rättat hen ett flertal gånger utan framgång.",
  "Du har en kollega som är en riktig besserwisser och det börjar gå dig på nerverna. På möten ska hen alltid säga emot det du säger, du vet att det är du som har rätt men hen får alltid sista ordet.",
  "Din kollega som sitter på skrivbordet bredvid har en dålig vana av att inte ta bort sina kaffekoppar som täcker största delen av skrivbordet. Nu har det gått så långt att hen börjar avlasta sina grejer på ditt skrivbort i brist på plats på sitt eget.",
  "Du har en kollega som alltid stör i tid och otid med dåliga Youtube-videor som du inte alls uppskattar, och helst slipper se. Hen tycker att det är hysteriskt roligt och du märker att hen blir nedstämd om du inte uppskattar det lika mycket.",
  "Du och ditt team ska ut på en AW. Sist så bjöd du på öl för Cygnis räkning och nu förväntar sig dina kollegor att du betalar i fortsättningen.",
  "Din kollega har svårt att fatta egna beslut och vänder sig till dig ideligen för att du ska fatta besluten åt hen. Du vet att hen tar åt sig äran om det är ett bra beslut men är duktig på att skylla ifrån sig på dig om det blir ett felaktigt beslut. Du vill därför inte ge råd eller ta beslut åt hen.",
  "Din semester närmar sig och din kollega dyker upp på jobbet genomförkyld och sitter och hostar och nyser. Kollegan pratar ingående om symptomen och hur hen blev smittad av sambon. Du känner hur bakterierna sprider sig i rummet och vill absolut inte bli sjuk inför den kommande utlandssemestern.",
  "Du och din kollega ska hålla ett föredrag och ni har delat upp uppgifterna så att det är din kollega som ska förbereda materialet till presentationen. Väl på plats inser du att hen inte genomfört sin uppgift och skyller på dig inför kunden.",
  "Du noterar att din kollega från Cygni endast använder merchandise från konkurrerande konsultbolag.",
  "Cygni anmäler ett lag till Bellmanstafetten, du ser att din kollega som inte är tävlingsinriktad eller motionerat det senaste decenniet anmäler sig. Du har legat i hårdträning inför loppet och vill verkligen att laget ska få en bra tid.",
  "Du har suttit på ditt drömuppdrag i sex månader. Klädseln är causal, typ jeans och t-shirt. Nu har den nya teamleadern bestämt att ni ska införa “slipsfredag”. Alla i ditt team förutom du blir överlyckliga men eftersom du varken äger slips eller skjorta är du inte lika entusiastisk.",
  "Du har suttit på ditt uppdrag i tre månader när du en morgon kommer tillbaka och ser att din arbetsplats är flyttad. Ditt skrivbord med bästa platsen vid fönstret är nu tagen av den nya konsulten från ett konkurrerande bolag.",
  "Din kollega envisas med att spela musik i högtalarna så hela kontorslandskapet hör. Du vill sitta och ha det lugnt och tyst och inte tvingas lyssna på hens dåliga musikval.",
  "Du hamnar på ett uppdrag där många går i strumplästen. Det är det värsta du vet! Utöver strumplästen är det främst en kollega som alltid har hål i stumporna så stortårn sticker ut och luktar fotsvett.",
  "Varje morgon när du kommer till jobbet säger du glatt godmorgon till dina kollegor utan att få någon respons. Det upprepar sig när det är dags för hemgång och efter en månad börjar du tappa hoppet om att få ett svar.",
  "Du sitter tillsammans med några andra Cygni-konsulter i ett tidspressat projekt där utvecklingschefen är mycket pressad och jobbar själv dygnet runt för att säkerställa en leverans. En fredag eftermiddag kallar han till stormöte, alla projektmedlemmar, konsulter som anställda, skall jobba flera timmar övertid varje dag från och med nu och fram till leverans om tre veckor.",
  "Du är på konferens när din rumskompis, klockan två på natten, bjuder in till fest i ert hotellrum. Du vill helst bara sova för att orka med morgondagens konferenspass.",
  "Din kollega envisas alltid med att ha fötterna på bordet, något som du tycker är sjukt störande.",
  "Du har vid flera tillfällen hört hur två av dina kollegor kallar varandra vid fula namn och är allmänt otrevliga mot varandra. Du konfronterar den ena kollegan som avfärdar dig med att de inte alls bråkar utan bara gillar att  ́slänga käft ́. Du tycker oavsett att det är otrevligt.",
  "En av dina kollegor i teamet som också är från Cygni jobbar väldigt mycket övertid. Du funderar på om det verkligen är hälsosamt.",
  "Din kollega från Cygni anmäler sig sjuk. Senare på kvällen springer ni på varann på en bar, du och kollegan småpratar om hur dagen har varit, det verkar inte alls som att kollegan har varit sjuk.",
  "Din kollega från Cygni anförtror sig till dig och uttrycker att hen inte gillar sin KC och helst skulle vilja byta. KC:n rådfrågar dig och undrar om din kollega sagt något efter att KC:n märkt att något inte är som det ska.",
  "Du och din Cygnikollega sitter i samma team. Kollegan levererar bra och är sällan sen/borta från uppdraget men du misstänker att hen har ett alkoholproblem. Din misstanke grundar sig i att hen dricker väldigt mycket på gemensamma AWs och efter konsultmöten.",
  "Din kollega på uppdraget är en duktig utvecklare men verkar göra större framgång på Candy Crush. Efter semestern har det eskalerat och kollegan smygspelar i tid och otid.",
  "Chefen i teamet kommer förbi och presenterar en nyanställd kollega. När chefen presenter ditt team för den nyanställda säger han om den manliga kollegan “här har vi vår programmerar-guru som löser alla problem” och om den kvinnliga kollegan “här har vi vår glädjespridare i gruppen”, du vet att det egentligen är din kvinnliga kollega som drar det tunga lasses och är den mest kompetenta av de två.",
  "Du har tackat ja till ett uppdrag på ett bettingbolag. Du trivs med dina kollegor och du tycker att teknikstacken passar dig så egentligen stortrivs du! När bidragen (ex barnbidrag, bostadsbidrag varje månad betalas ut märker du att insatserna ökar. Du tycker inte att det känns moraliskt rätt och börjar tveka till uppdraget.",
  "Du sitter i ett team där alla är seniora utvecklare och väldigt hög kompetens. Samarbetet är obefintligt och koden är är enormt svårläst. Det är långsam produktion och alla sitter med hörlurar på och suckar djupt.",
  "Till ditt team kommer en ny kollega. Hen är insåld som senior utvecklare som ska lösa “alla era problem”. Efter bara några dagar inser du att hen inte alls är så senior som det utlovats. Det är istället du som får lära hen.",
  "I ditt team finns det flera kvinnliga kollegor. Du har på senaste tid noterat att er teamlead alltid vänder sig till de manliga kollegorna när det är svåra nötter att knäcka trots att de kvinnliga kollegorna är lika kompetenta.",
  "Du har en kollega som aldrig kan erkänna att hen har fel och inte kan ta kritik."
]