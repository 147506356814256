export const randomNumber = (bound) => {
  return Math.floor(Math.random() * bound);
}

export const shuffle = (array) => {
  let m = array.length;

  while (m) {
    let i = randomNumber(m--);
    let t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}