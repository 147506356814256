import "./grid.css";
import React from "react";
import {useState} from "react";
import {useLocalStorage} from "../hooks";
import {questions} from "../questions";
import Card from "./Card";
import Timer from "./Timer";
import {randomNumber, shuffle} from "../game/utils";
import {cardRules} from "../game/rules";

const initialState = {
  gameStarted: false
};

function App() {
  const [unAnsweredQuestions, setQuestions, clearQuestions] = useLocalStorage("questions", shuffle(questions));
  const [state, setState] = useState(initialState)

  const randomCardType = () => {
    const keys = Object.keys(cardRules);
    const cardType = keys[randomNumber(keys.length)];
    const card = cardRules[cardType];
    return {
      type: cardType,
      ...card
    };
  };

  const getCard = () => {
    const questions = unAnsweredQuestions;
    const question = questions.pop();
    setQuestions(questions);
    let {rule, time} = randomCardType();
    return {
      rule: rule,
      question: question || "Inga fler frågor!",
      subTitle: "Vad gör du?",
      time: time,
      key: questions.length,
    }
  }

  const reset = () => {
    clearQuestions();
    setState(initialState);
  }

  return (
      <div className="container">
        {state.gameStarted &&
        <Card title={state.card.rule} question={state.card.question} subtitle={state.card.subTitle}/>}
        {state.gameStarted && <Timer key={state.card.key} time={state.card.time}/>}
        <div>
          <button onClick={() => setState({gameStarted: true, card: getCard()})}>Dra ett nytt kort</button>
          <button onClick={reset}>Återställ spelet</button>
        </div>
      </div>
  );
}

export default App;
