import "./Timer.css";
import React, {useState, useEffect} from "react";

const MINUTE = 60;

const readableTimeFormat = (value) => {
  const minutes = Math.floor(value / MINUTE);
  const seconds = value % MINUTE;
  const readableSeconds = `${seconds} sekunder`;
  if (minutes > 0) {
    return `${minutes} minuter${seconds > 0 ? `och ${readableSeconds}` : ""}`;
  }
  return readableSeconds;
}

const timerFormat = (value) => {
  const minutes = Math.floor(value / MINUTE);
  const seconds = value % MINUTE;
  return `0${minutes}:${seconds > 9 ? seconds : "0" + seconds}`;
}

const initialState = {
  started: false,
  done: false,
  timer: null
};

export default function Timer({time}) {
  const [state, setState] = useState(initialState);
  const [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {
    clearInterval(state.timer);
    setTimeLeft(time);
  }, [time])

  useEffect(() => {
    if (timeLeft === 0) {
      clearInterval(state.timer);
      setState(s => ({...s, started: false, done: true}));
    }
  }, [timeLeft, state.timer])

  useEffect(() => {
    return () => clearInterval(state.timer);
  }, [state.timer])

  const startTimer = () => {
    const timer = setInterval(
        () => setTimeLeft((count) => count - 1),
        1000
    )
    setState({timer, started: true, done: false});
  }

  const stopTimer = () => {
    clearInterval(state.timer);
    setState(s => ({...s, started: false, done: false}));
  }

  return (
      <div className="timer col-12">
        {(!state.started && !state.done) && <h2>Ni har {readableTimeFormat(timeLeft)} att svara på kortet</h2>}
        {(state.started || state.done) && <h1 className={timeLeft < 10 ? "pulse" : ""}>{timerFormat(timeLeft)}</h1>}
        <span>Starta tiden när ni är redo.</span>
        <span>
          <button onClick={startTimer} disabled={state.started || state.done}>Start</button>
          <button onClick={stopTimer} disabled={!state.started || state.done}>Stop</button>
        </span>
      </div>
  )
}